<template>
    <div
        class="flex space-x-1 rounded-lg bg-gray-100 dark:bg-gray-900 p-0.5"
        role="serviceSwitcher"
        aria-orientation="horizontal">
        <NuxtLink
            :to="(isServicePage) ? '/' : '/service'">
            <BaseButton
                color="secondary">
                <div class="flex items-center">
                    <Icon name="heroicons:chevron-right" />
                    <span class="text-xs">
                        <span v-if="isServicePage">Zum Antragsbereich</span>
                        <span v-else>Zum Verwaltungsbereich</span>
                    </span>
                </div>
            </BaseButton>
        </NuxtLink>
    </div>
</template>

<script setup>
import {useRoute} from "vue-router";

const route = useRoute()
const isServicePage = computed(() => {
    return route.path.startsWith('/service')
})
</script>