<template>
    <div>
        <Alert
            v-if="authStore.isAuthenticated !== true"
            type="warning"
            text="Funktion für unangemeldete Nutzer noch in der Entwicklung" />
        <div v-else>
            <div>
                <div class="text-sm mb-4">
                    Jahr: {{ staticStore.activeYear?.id }}
                </div>

                <label
                    for="default-search"
                    class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">{{ $t('fundingPrograms.slideOver.search') }}</label>
                <div class="relative mb-3">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                            class="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20">
                            <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input
                        id="default-search"
                        v-model="search"
                        type="search"
                        class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        :placeholder="$t('fundingPrograms.slideOver.searchPlaceholder')"
                        required>
                </div>
            </div>
            <FundingProgramsAccordion
                class="p-2"
                :parent-funding-programs="parentFundingPrograms"
                :child-funding-programs="childFundingPrograms"
                :loading="loading"
                @add-new-form-process="addNewFormProcess" />
        </div>
    </div>
</template>

<script setup>
import {$lara, $larafetch} from '@/utils/$larafetch'
import {useNuxtApp} from "nuxt/app";
import debounce from "lodash/debounce";
import {useNotification} from '@/composables/useNotification'
import {useI18n} from "vue-i18n";
import {useSlideOverStore} from "@/store/slideOver";
import {useAuthStore} from "@/store/auth";
import {useStatic} from "@/store/static";

const fundingPrograms = ref([])
const search = ref('')
const loading = ref(0)
const router = useRouter();
const {t: $t} = useI18n()
const notification = useNotification()
const slideOverStore = useSlideOverStore()
const authStore = useAuthStore()
const staticStore = useStatic()

const parentFundingPrograms = computed(() => {
    const parentFundingPrograms = fundingPrograms.value?.filter(fundingProgram => fundingProgram.parent_funding_program_id === null)
    const parentFundingProgramIds = parentFundingPrograms.map(fundingProgram => fundingProgram.id)
    fundingPrograms.value?.forEach(fundingProgram => {
        if (fundingProgram.parent_funding_program?.id &&
            !parentFundingProgramIds.includes(fundingProgram.parent_funding_program?.id)) {
            parentFundingPrograms.push(fundingProgram.parent_funding_program)
            parentFundingProgramIds.push(fundingProgram.parent_funding_program?.id)
        }
    })
    return parentFundingPrograms
})

const childFundingPrograms = computed(() => {
    return fundingPrograms.value?.filter(fundingProgram => fundingProgram.parent_funding_program_id !== null)
})

onMounted(() => {
    staticStore.getStaticData()
    fetchFundingPrograms()
});

function fetchFundingPrograms() {
    if (authStore.isAuthenticated !== true) {
        // todo: reactivate when concept for unauthenticated users is ready
        return
    }

    const params = {
        search: search.value,
        output: 'collectionAll'
    }

    if (slideOverStore.context && slideOverStore.context.type && slideOverStore.context.type === 'fundingProgram') {
        params.filter = [
            {column: 'funding_programs.id', operator: '=', value: slideOverStore.context.id}
        ]
    }

    $larafetch(useNuxtApp().$apiRoute('spa.processes.fundingPrograms.index'), {
        params: {...params}
    }).then(response => {
        fundingPrograms.value = response.data
    }).catch(error => {
        throw error
    })
}

function addNewFormProcess(form) {
    loading.value = form.id
    let eventAlreadyExisting = false

    const body = {
        formId: form.id,
        yearId: staticStore.activeYear.id
    }

    if (slideOverStore.context && slideOverStore.context.type) {
        switch (slideOverStore.context.type) {
        case 'event':
            eventAlreadyExisting = true
            body.eventId = slideOverStore.context.id
            break
        case 'process':
            eventAlreadyExisting = true
            body.processId = slideOverStore.context.id
            break
        default:
            break
        }
    }

    $lara.post(useNuxtApp().$apiRoute('spa.processes.formProcesses.store'), {
        body: body,
        formDataRequest: true
    }).then((response) => {
        loading.value = 0
        notification.success($t('formProcesses.createSuccess'))
        slideOverStore.resetSlideOver()
        router.push({path:useLocalePath()('/formProcess/' + response.id), hash: eventAlreadyExisting ? '#formProcess' : ''});
    }).catch((error) => {
        loading.value = 0
        console.log(error)
        notification.error($t('formProcesses.createError'), error.data?.errors)
    })
}

watch(() => search.value, () => {
    fetchDebounced()
})

const fetchDebounced = debounce(() => {
    fetchFundingPrograms()
}, 500)
</script>

