<template>
    <div class="bg-white dark:bg-gray-900">
        <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <dl class="space-y-6 divide-y divide-gray-900/10">
                <Disclosure
                    v-for="parentFundingProgram in props.parentFundingPrograms"
                    :key="parentFundingProgram.name"
                    v-slot="{ open }"
                    as="div"
                    class="pt-6">
                    <dt>
                        <DisclosureButton
                            class="flex w-full items-start justify-between text-left text-base">
                            <div>
                                <span class="text-base font-semibold leading-7">{{ parentFundingProgram.name }}</span>
                                <div
                                    v-if="parentFundingProgram.forms?.length > 0"
                                    class="mt-3 flex flex-wrap gap-5">
                                    <BaseButton
                                        v-for="form in parentFundingProgram.forms"
                                        :key="form.id"
                                        size="sm"
                                        :disabled="loading === form.id"
                                        @click="addNewFormProcess($event, form)">
                                        <span v-if="loading === form.id">
                                            <LoadingSpinner />
                                        </span>
                                        <span v-else>
                                            {{ form.identifier }}
                                        </span>
                                    </BaseButton>
                                </div>
                            </div>
                            <span
                                v-if="children(parentFundingProgram).length > 0"
                                class="ml-6 flex h-7 items-center">
                                <Icon
                                    v-if="!open"
                                    class="h-6 w-6"
                                    aria-hidden="true"
                                    name="heroicons:plus"
                                    color="text-base" />
                                <Icon
                                    v-else
                                    class="h-6 w-6"
                                    aria-hidden="true"
                                    name="heroicons:minus"
                                    color="text-base" />
                            </span>
                        </DisclosureButton>
                    </dt>
                    <DisclosurePanel
                        v-if="children(parentFundingProgram).length > 0"
                        as="dd"
                        class="mt-2 pr-12">
                        <div class="my-10">
                            <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-1 lg:gap-y-8">
                                <div
                                    v-for="childFundingProgram in children(parentFundingProgram)"
                                    :key="childFundingProgram.name"
                                    class="relative pl-16 text-sm">
                                    <dt class="font-semibold text-base">
                                        <div
                                            class="absolute left-0 top-0 flex h-11 w-11 items-center justify-center rounded-lg bg-gray-100 dark:bg-gray-700">
                                            <p class="text-gray-600 dark:text-gray-300">
                                                {{ childFundingProgram.number }}
                                            </p>
                                        </div>
                                        {{ childFundingProgram.name }}
                                    </dt>
                                    <dd class="mt-1 text-gray-600 dark:text-gray-300 flex flex-col">
                                        {{ childFundingProgram.description }}

                                        <div
                                            v-if="childFundingProgram.forms.length > 0"
                                            class="mt-3 flex flex-wrap gap-5">
                                            <BaseButton
                                                v-for="form in childFundingProgram.forms"
                                                :key="form.id"
                                                size="sm"
                                                :disabled="loading === form.id"
                                                @click="addNewFormProcess($event, form)">
                                                <span v-if="loading === form.id">
                                                    <LoadingSpinner />
                                                </span>
                                                <span v-else>
                                                    {{ form.identifier }}
                                                </span>
                                            </BaseButton>
                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </DisclosurePanel>
                </Disclosure>
            </dl>
        </div>
    </div>
</template>

<script setup>
const emits = defineEmits(['addNewFormProcess'])

const props = defineProps({
    parentFundingPrograms: {
        type: Array,
        default: () => []
    },
    childFundingPrograms: {
        type: Array,
        default: () => []
    },
    loading: {
        type: Number,
        default: 0
    }
})

const children = (parentFundingProgram) => {
    return props.childFundingPrograms.filter(childFundingProgram => childFundingProgram.parent_funding_program_id === parentFundingProgram.id)
}

function addNewFormProcess(event, form) {
    event.preventDefault()
    emits('addNewFormProcess', form)
}
</script>
